import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services/Api'
import { RecapUnit } from "."

export const Recaps = ({ type: initType }) => {

    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [news, setNews] = useState()
    const [selectedNews, setSelectedNews] = useState()

    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const data = await list2('market-indicator', type)
            dispatch({ type: 'spin off' })
            setNews(data)
        }
        fetch()
    }, [type])

    useEffect(() => {
        if (news) setSelectedNews(news[0])
    }, [news])

    const onSelectNews = (item, event) => {
        event.preventDefault()
        setSelectedNews(item)
        document.getElementsByName('_top')[0].scrollIntoView(0, -200)
    }

    return (
        <div className='column-center pt-2'>
            <a name='_top'></a>
            {news && selectedNews &&
            <div className='w-95p'>
                {<Paper>
                    <div className='p-1'>
                        <RecapUnit title={selectedNews.title} date={selectedNews.date} source={selectedNews.source} paragraph={selectedNews.paragraph || selectedNews.json.paragraph} />
                    </div>
                </Paper>}
                <div className='p-1 my-3'>
                    {news.filter(item => item.id !== selectedNews.id).map(item => {
                        return (
                            <div className='mb-6 column-start' key={`key_news_item_${item.id}`} >
                                <div className='mr-2'>{item.date}</div>
                                <div><a href='#_top' onClick={event => onSelectNews(item, event)}  className='f-02 bold'>{item.title}</a></div>
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}