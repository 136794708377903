import { useState, useEffect, useContext } from 'react'
import { list2 } from '../../../services/Api'
import { Lastupdate, StockChart } from '../../../components'
import { TAFilter } from '.'
import { oscillators } from '../../../config'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import { DispatchContext } from "../../../reducers";

export const PriceModel = () => {

    const dispatch = useContext(DispatchContext)
    const [prices, setPrices] = useState([])

    const [conditions, setConditions] = useState({ instrument: { control: 'PriceOhlc01', name: 'ICE Brent Crude' }, overlay: 'ema', oscillator: oscillators[0] })
    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    useEffect(() => {
        async function fetch () {
            if (conditions?.instrument?.control) {
                dispatch({ type: 'spin on' })
                const prices = await list2('priceOhlc', conditions.instrument.control)
                dispatch({ type: 'spin off' })
                setPrices(prices)
            }
        }
        fetch()
    }, [conditions])

    return (
        <div>
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type='priceOhlc' align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <TAFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className='row-center'>
                {prices && prices.length > 0 && conditions && <StockChart data={prices} conditions={conditions} />}
            </div>
        </div>
    )
}