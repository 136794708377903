import { useState, useEffect, useContext, useRef } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, FormControl, Select, InputLabel, MenuItem, Input, Checkbox, Divider, ListItemText, Button } from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import moment from 'moment';
import { Lastupdate, Message, GeneralChart } from '../../../components'
import { list2, getYearRange } from '../../../services'
import { DispatchContext } from "../../../reducers";
import { usePrevious } from '../../../hooks'

export const FloatingFilter = ({ init, callback }) => {
    const dispatch = useContext(DispatchContext)
    const childRef = useRef()
    const [countries, setCountries] = useState([])
    const [category, setCategory] = useState(init.category)
    const [country, setCountry] = useState(init.value)
    const [year, setYear] = useState(init.year)
    const [split, setSplit] = useState('No')
    const years = getYearRange('2018-01-01')
    const categories = ['Crude', 'LPG', 'Naphtha', 'Gasoline', 'Gasoil', 'Jet', 'Fuel Oil']
    const splits = ['No', 'Yes']
    const previous = usePrevious({ category })

    useEffect(() => {
        async function fetch () {
            if (previous) dispatch({ type: 'spin on' })
            const values = await list2('floating/condition', category)
            if (previous) dispatch({ type: 'spin off' })
            setCountries(values)
            if (!previous) setCountry(values.map(({ id }) => id))
        }
        fetch()
    }, [category])

    const onSelectCountry = (event) => {
        const { value } = event.target
        if (value.includes('select_all')) {
            if (value.length === countries.length + 1) {
                setCountry([])
            } else {
                setCountry(countries.map(({ id }) => id))
            }
        } else {
            setCountry(value)
        }
    }

    const onSelectYear = (event) => {
        setYear(event.target.value)
    }

    const onSelectCategory = (event) => {
        setCategory(event.target.value)
    }

    const onSelectSplit = (event) => {
        setSplit(event.target.value)
    }

    const confirm = () => {
        if (country.length === 0) {
            childRef.current.showError(`country is empty`)
        } else {
            const name =  country.map((i) => {
                const { name } = countries.find(({ id }) => id === i)
                return name
            })
            callback({ category, value: country, year, name, split })
        }
    }

    return (
        <div className="row-start">
            <Message ref={childRef} />
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Year</InputLabel>
                    <Select value={year} onChange={onSelectYear} >
                        {years.map((y) => (
                            <MenuItem key={`key_floating_year_${y}`} value={y}>
                                {moment(y).format('YYYY')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Category</InputLabel>
                    <Select value={category} onChange={onSelectCategory} >
                        {categories.map((c) => (
                            <MenuItem key={`key_floating_category_${c}`} value={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='row-between my-2'>
                <FormControl>
                    <InputLabel>Country</InputLabel>
                    <Select multiple value={country} onChange={onSelectCountry} input={<Input /> } displayEmpty={true}
                        renderValue={(selected) => {
                            if (country.length === countries.length) {
                                return 'All'
                            } else if (selected.includes('Others')) {
                                return 'Multiple Selected';
                            } else if (selected.length === 1) {
                                return countries.find(({ id }) => id === selected[0])?.name;
                            } else if (selected.length === 0) {
                                return ''
                            } else {
                                return `${selected.length} Selected`
                            }
                        }} >
                        <MenuItem key={`key_floating_country_select_all`} value={'select_all'}>
                            <Checkbox checked={country.length === countries.length} />
                            <ListItemText primary={'All'} />
                        </MenuItem>
                        <Divider variant="fullWidth" />
                        {countries.map(({ name, id }) => (
                            <MenuItem key={`key_floating_country_${id}`} value={id}>
                                <Checkbox checked={country.indexOf(id) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Split</InputLabel>
                    <Select value={split} onChange={onSelectSplit} >
                        {splits.map((s) => (
                            <MenuItem key={`key_floating_split_${s}`} value={s}>
                                {s}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='row-center mt-6'>
                <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
            </div>
        </div>
    )
}

export const FloatingModel = () => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [expanded, setExpanded] = useState(false)
    const [conditions, setConditions] = useState({ category: 'Crude', year: moment().format('YYYY-01-01'), value: [], name: ['All'] })

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const callback = event => {
        setConditions(event)
        setExpanded(false)
    }

    useEffect(() => {
        async function fetch () {
            if (conditions) {
                dispatch({ type: 'spin on' })
                const data = await list2('storage', 'floating', conditions)
                dispatch({ type: 'spin off' })
                setData(data)
            }
        }
        fetch()
    }, [conditions])

    return (
        <div className='w-full'>
            <Accordion expanded={expanded === 'panel'} className={`w-95p ${expanded === 'panel' ? '' : 'box-shadow-0'}`} onChange={handleChange('panel')}>
                <AccordionSummary expandIcon={<FilterList />}>
                    <Lastupdate type='storage:floating:kpler' align='left'/>
                </AccordionSummary>
                <AccordionDetails className='column'>
                    <FloatingFilter init={conditions} callback={callback} />
                </AccordionDetails>
            </Accordion>
            <div className="row-center">
                {data && conditions && <GeneralChart param={data} />}
            </div>
        </div>
    )
}