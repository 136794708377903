import React, { useState, useEffect, useContext } from 'react';
import { list2 } from '../../../services/Api'
import { Button, Select, MenuItem } from '@mui/material';
import { DispatchContext } from "../../../reducers";
import { overlays, oscillators } from '../../../config'

export const TAFilter = ({ init, callback }) => {
    const dispatch = useContext(DispatchContext)
    const [instruments, setInstruments] = useState([])
    const [instrument, setInstrument] = useState(init.instrument)
    const [overlay, setOverlay] = useState(init.overlay)
    const [oscillator, setOscillator] = useState(init.oscillator)

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const instruments = await list2('priceOhlc', 'instruments')
            dispatch({ type: 'spin off' })
            setInstruments(instruments)
        }
        fetch()
    }, [])

    useEffect(() => {
        if (instruments?.length > 0) setInstrument(instruments[0])
    }, [instruments])

    const onSelectInstrument = event => {
        if (event) {
            setInstrument(event.target.value)
        }
    }

    const onSelectOverlay = event => {
        if (event) {
            setOverlay(event.target.value)
        }
    }

    const onSelectOscillator = event => {
        if (event) {
            setOscillator(event.target.value)
        }
    }

    const confirm = () => {
        callback({ instrument, overlay, oscillator })
    }
    return (
        <div className="row-start">
            <div className='row-between my-2'>
                <label className=''>Instrument</label>
                <Select value={instrument} onChange={onSelectInstrument}>
                    {instruments.map(instrument => (
                        <MenuItem key={`ta_instrument_key_${instrument.control}`} value={instrument}>{instrument.name}</MenuItem>
                    ))}
                </Select>
            </div> 
            <div className='row-between my-2'>
                <label className=''>Overlay</label>
                <Select value={overlay} onChange={onSelectOverlay}>
                    {overlays.map(overlay => (
                        <MenuItem key={`ta_overlay_key_${overlay.name}`} value={overlay.name}>{overlay.title}</MenuItem>
                    ))}
                </Select>
            </div> 
            <div className='row-between my-2'>
                <label className=''>Oscillator</label>
                <Select value={oscillator} onChange={onSelectOscillator}>
                    {oscillators.map(oscillator => (
                        <MenuItem key={`ta_oscillator_key_${oscillator.name}`} value={oscillator}>{oscillator.title}</MenuItem>
                    ))}
                </Select>
            </div> 
            <div className='row-center mt-6'>
                <Button variant='outlined' onClick={confirm} className='ml-2'>Confirm</Button >
            </div>
        </div>
    )
}
