import { useState } from "react";
import { Divider, Backdrop, Card, CardHeader, CardContent, Modal, Fade, Tabs, Tab, Typography, IconButton, Grid } from '@mui/material'
import { ProfileSummary } from "./ProfileSummary";
import { options as companyOptions } from '../pages/Profile/CompanyProfile'
import { options as countryOptions } from '../pages/Profile/CountryProfile'
import { keys as companyKeys, CompanyDetailSector } from '../pages/Profile/CompanyDetail';
import { keys as countryKeys, CountryDetailSector } from "../pages/Profile/CountryDetail";
import { sleep } from "../services";

export const Event = ({ event }) => {
    const { name, country, schedule } = event
    const [showCountry, setShowCountry] = useState(false)
    const [open, setOpen] = useState(false)
    const [id, setId] = useState()
    const [company, setCompany] = useState()
    const [scrollKeysCountry, setScrollKeysCountry] = useState([])
    const [scrollKeysCompany, setScrollKeysCompany] = useState([])
    const [scrollKey, setScrollKey] = useState('overview')

    const onShowCountry = () => {
        setShowCountry(true)
        const scrollOptions = []
        for (const option of countryOptions) {
            const exist = scrollOptions.find(({ label }) => label === option.label)
            if (country[option.value] && !exist) {
                scrollOptions.push(option)
            }
        }
        setScrollKeysCountry(scrollOptions)
        onTab(null, 'overview')
    }
    
    const handleClose = () => {
        setOpen(false)
    }
    const onSelect = (event) => {
        setId(event)
        setOpen(true)
    }
    const onCallback = (event) => {
        handleClose()
        if (event !== 'close') {
            setCompany(event)
            const scrollOptions = []
            for (const option of companyOptions) {
                const exist = scrollOptions.find(({ label }) => label === option.label)
                if (event[option.value] && !exist) {
                    scrollOptions.push(option)
                }
            }
            setScrollKeysCompany(scrollOptions)
            onTab(null, 'companyProfileSnapshot')
        }
    }

    const onTab = async (event, key) => {
        setScrollKey(key)
        await sleep(0)
        const el = document.getElementById(key)
        el.scrollIntoView()
    }

    return (
        <div className='mb-6'>
            {country && !showCountry && !company && <div className="row-between m-5">
                <div className="column-left">
                    <Typography variant='h6' color='textPrimary'>{name}</Typography>
                    <IconButton onClick={onShowCountry}><Typography variant='subtitle2' color='primary'>View Country Profile</Typography></IconButton>
                </div>
                <img loading="lazy" width="100" src={country.src} alt="" />
            </div>}
            {!country && !company && <div className="row-between m-5">
                <div className="column-left">
                    <Typography variant='h6' color='textPrimary'>{name}</Typography>
                </div>
            </div>}
            
            <Divider light />

            {!company && showCountry && <div className='onTop2 bg-white w-full py-3'>
                <div className='row-between w-full'>
                    <div className='row-left ml-1'><img loading="lazy" width="100" src={country.src} alt="" /></div>
                    <Typography variant='h6' color='textPrimary'>{country.name}</Typography>
                    <IconButton className='mr-2' onClick={() => setShowCountry(false)}><Typography variant='subtitle1' color='primary'>Back</Typography></IconButton >
                </div>
                <div className='row-between w-full mt-1'>
                    <Tabs value={scrollKey} onChange={onTab} variant="scrollable" className="bg-teal_lighter dark_gray">
                        {scrollKeysCountry.map(({ label, value }) => {
                            return <Tab key={`key_country_profile_index_${value}`} label={<label className='fs-16'>{label}</label>} value={value} />
                        })}
                    </Tabs>
                </div>
            </div>}
            {!company && showCountry && <div className=''>
                {Object.entries(country).filter(([key, items]) => countryKeys.includes(key) && items).map(([key, items]) => {
                    return scrollKey === key && <CountryDetailSector key={`key_country_profile_sector_${key}`} id={key} items={items} />
                })}
            </div>}
            {company && <div className='onTop2 bg-white w-full py-3'>
                <div className='row-between w-full'>
                    <div className='column-left ml-1'>
                        <div><img loading="lazy" width="100" src={company.src} alt="" /></div>
                    </div>
                    {company.price && <div className='row-center'>
                        <Typography variant='caption'>{company.price.close}</Typography>&nbsp;
                        <Typography variant='caption'>{(company.price.color === 'red' ? <span className={company.price.color}>&#9660;{company.price.diffPercentage}%</span> : <span className={company.price.color}>&#9650;{company.price.diffPercentage}%</span>)}</Typography>
                    </div>}
                    <IconButton className='mr-2' onClick={() => setCompany(null)}><Typography variant='subtitle1' color='primary'>Back</Typography></IconButton >
                </div>
                <div className='row-between w-full mt-1 '>
                    <Tabs value={scrollKey} onChange={onTab} variant="scrollable" className="bg-teal_lighter dark_gray">
                        {scrollKeysCompany.map(({ label, value }) => {
                            return <Tab key={`key_company_profile_index_${value}`} label={<div className='row-center fs-16'>{label}</div>} value={value} />
                        })}
                    </Tabs>
                </div>
            </div>}
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition
                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop: 60 }}
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='' style={{ width: '90%', height: 330, maxWidth: 800 }} >
                        <ProfileSummary id={id} callback={onCallback} />
                    </div>
                </Fade>
            </Modal>
            {!company && !showCountry && <Grid container>
                {schedule.map(({ day, date, events }) => {
                    return <Grid item md={6} lg={3} key={`key_company_card_${date}`}>
                        <Card style={{ width: '90%', height: '90%', minWidth: 350, paddingBottom: 20 }} className='column-start m-4' >
                            <CardHeader title={date} subheader={day} />
                            <CardContent style={{ textAlign: "left" }}>
                                {events.map(({ time, items }) => <div className="column-start">
                                    <div className="row-center my-4">
                                        <div className="flex-1 ml-2">
                                            <Typography variant='subtitle2' color='textSecondary'>{time}</Typography>
                                        </div>
                                        <div className="column-center flex-2 mw-200">
                                            {items.map(({ type, text, font, name, id, src }) => <>
                                                {type === 'text' && <div className={`w-full text-center ${font}`}><label>{text}</label></div>}
                                                {type === 'company' && <div className="w-full"><img loading="lazy" width="100%" height="40" src={src} alt={name} onClick={() => onSelect(id)} /></div>}
                                            </>)}
                                        </div>
                                    </div>
                                </div>)}
                            </CardContent>
                        </Card>
                    </Grid>
                })}
            </Grid>}
            {company && <div className=''>
                {Object.entries(company).filter(([key, content]) => companyKeys.includes(key) && content).map(([key, content]) => {
                    return scrollKey === key && <CompanyDetailSector key={`key_company_profile_sector_${key}`} id={key} content={content} />
                })}
            </div>}
        </div>
    );
}