import { useState, useEffect, useContext, useRef } from 'react';
import { Button, Dialog } from '@mui/material'
import Cookies from 'js-cookie'
import { Parser } from 'json2csv'
import { FileList, Icon, Message, MenuComponent } from '..';
import { list2, post2, pascal } from '../../services'
import { DispatchContext } from "../../reducers";

export const ExportFile = ({ data, subject, type, color = 'green' }) => {
    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const [exportType, setExportType] = useState('Send')
    const [open, setOpen] = useState(false)
    const parser = new Parser();
    const [header, setHeader] = useState()
    const [externalData, setExternalData] = useState([])
    const [selectedData, setSelectedData] = useState(data)
    const [access, setAccess] = useState(false)
    const options = [
        { label: <label className='f-06 dark_blue'><Icon icon='faEnvelope' /><label className='f-02 ml-1'>Send Email</label></label>, name: 'Send' }, 
        { label: <label className='f-06 green4'><Icon icon='faDownload' /><label className='f-02 ml-1'>Download</label></label>, name: 'Download' }
    ]
    useEffect(() => {
        async function fetch() {
            const auth = await list2('auth')
            setAccess(auth === true)
        }
        fetch()
    }, [])
    useEffect(() => {
        if (data && type) {
            async function fetch() {
                if (type) {
                    const conf = await list2('configuration/export', type)
                    setExternalData(conf)
                }
            }
            fetch()
        }
    }, [data, type])
    useEffect(() => {
        if (data) {
            setHeader(<label className='f-02 ml-1'>Export {data.length + externalData.length} Files</label>)
        }
    }, [data, externalData])

    const onSelectExportType = ({ name }) => {   
        setExportType(pascal(name))
        onPopup()
    }
    const onSelectFile = (event) => {
        setSelectedData(event)
    }
    const onPopup = () => {
        if (data) setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const onConfirm = async () => {
        setOpen(false)
        if (!selectedData || selectedData.length === 0) return
        const files = [], external = []
        selectedData.forEach(({ filename, type, file, data, extl, category, name, extension, lastupdate }) => {
            if (extl) {
                external.push({ filename, type, category, name, extension, lastupdate })
            } else {
                files.push({ filename, type, file: type === 'text/csv' ? parser.parse(data) : file })
            }
        })
        if (exportType === 'Send') {
            dispatch({ type: 'spin on' })
            const { data: result } = await post2('user/export', { files, external, subject })
            dispatch({ type: 'spin off' })
            if (result === true) {
                messageRef.current.showSuccess('Export Data Success')
            } else {        
                messageRef.current.showError(result.error)
            }
        } else {
            const externalDownloads = []
            if (external.length > 0) {
                dispatch({ type: 'spin on' })
                const { data: result } = await post2('user/download', external)
                dispatch({ type: 'spin off' })
                externalDownloads.push(...result.map(({ filename, type, data: { data } }) => {
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr], { type });
                    return { filename, type, blob }
                }))
            }
            for (const { filename, type, file, blob } of [...files, ...externalDownloads]) {
                const url = type === 'text/csv' ? encodeURI(`data:text/csv;charset=utf-8,${file}`) : URL.createObjectURL(blob)
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            messageRef.current.showSuccess('Download Data Success')
        }
    }
    return (
        <div className={``}>
            <Message ref={messageRef} />
            {access && data && <MenuComponent label={<label className='f-08 up-3'><Icon icon='faFileDownload' color={color} /></label>} header={header} options={options} callback={onSelectExportType} />}
            <Dialog onClose={onClose} open={open}>
                {exportType === 'Send' && <div className='nw-350 my-3 px-5 bold-5 f-02'><label>To</label><label className='underline ml-1'>{Cookies.get('username')}</label></div>}
                <FileList files={data} external={externalData} callback={onSelectFile} />
                <div className='row-between px-3 my-3'>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm}><label className='dark_blue bold-5'>{exportType}</label></Button>
                </div>
            </Dialog>
        </div>
    )
}