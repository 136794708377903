import React, { useState, useEffect, useContext } from 'react';
import { list2 } from '../../../services/Api'
import { Icon, MenuComponent, PieChart, StepChart, LaycanChart } from '../../../components';
import { Grid } from '@mui/material'
import { DispatchContext } from "../../../reducers";

export const WindowDealReport = ({ type }) => {

    const dispatch = useContext(DispatchContext)
    const [products, setProducts] = useState([])
    const [dates, setDates] = useState([])
    const [pieData, setPieData] = useState({ buyer: [], seller: [] })
    const [stepData, setStepData] = useState([])
    const [laycanData, setLaycanData] = useState([])
    const [product, setProduct] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const [products, dates] = await Promise.all([
                list2('product', null, type),
                list2('windowDeal', 'range')
            ])
            setProducts(products)
            setDates(dates)
        }
        fetch()
    }, [])

    useEffect(() => {
        if (products.length > 0) {
            setProduct(products.find(({ disabled }) => !disabled))
        }
    }, [products, type])

    useEffect(() => {
        if (dates.length > 0) setDate(dates[0])
    }, [dates])


    useEffect(() => {
        async function fetch () {
            if (!product || !date) return
            const query = { product: product.category, date: date.name }
            dispatch({ type: 'spin on' })
            const { step, buyer, seller, laycan } = await list2('windowDeal', 'report', query)
            dispatch({ type: 'spin off' })
            setPieData({ buyer, seller })
            setStepData(step)
            setLaycanData(laycan)
        }
        fetch()
    }, [product, date])

    const onSelectProduct = event => {
        setProduct(event)
    }

    const onSelectDate = event => {
        setDate(event)
    }

    return (
        <>
            {product && date && <div className='column-center mb-4'>
                {!type && <div className='row-right dark_gray w-95p'>
                    <label className='mr-2'>
                        <MenuComponent label={<Icon icon='faCaretSquareDown' size='sm' />} options={products} callback={onSelectProduct} />
                    </label>
                    <label className='mr-2'>
                        <MenuComponent label={<Icon icon='faHistory' size='sm' />} options={dates} callback={onSelectDate} />
                    </label>
                </div>}
                <div className=''>
                    {stepData && <StepChart data={stepData} />}
                </div>
                {!stepData && laycanData.length === 0 && !pieData.buyer && !pieData.seller && 
                <div className='column-center'>
                        <div className='ft-title f-04'>{product?.category}</div>
                        <div className='ft-title f-02 mt-6'>No Data</div>
                </div>}
                <Grid container className='row-center'>
                    <Grid item md={6}>
                        {pieData.buyer && <PieChart data={pieData.buyer} />}
                    </Grid>
                    <Grid item md={6}>
                        {pieData.seller && <PieChart data={pieData.seller} />}
                    </Grid>
                </Grid>
                <Grid container>
                    {laycanData?.map((data, idx) => <Grid key={`key_laycan_chart_${idx}`} item md={6}>
                        <LaycanChart data={data} />
                    </Grid>)}
                </Grid>
                <div className=''></div>
            </div>}
        </>
    )
}