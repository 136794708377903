import React, { useEffect, useState, useContext } from 'react';
import { Grid, IconButton, Card, CardContent, Tabs, Tab } from '@mui/material'
import { withStyles } from '@mui/styles';
import { list2, sleep } from '../../services';
import { DispatchContext } from "../../reducers";
import { ExportPpt, Icon } from '../../components';
import { SelectBox } from './CompanyProfile'
import { keys as countryKeys, CountryDetailSector } from "./CountryDetail";

export const options = [
    { value: 'overview', label: 'Overview' },
    { value: 'demand', label: 'Supply/Demand' },
    { value: 'infrastructure', label: 'Infrastructure' },
    { value: 'trade', label: 'Trade' },
    { value: 'competitors', label: 'Competitors' },
    { value: 'energyTransition', label: 'Energy Transition' },
    // { value: 'tradeVolume', label: 'Trade Volume' },
]

export const CountryList = ({ items, callback, width='160px', height='100px' }) => {
    const onSelect = event => {
        callback(event)
    }
    return (
        <Grid container className='row-around px-3'>
            {items.map(item => (
                <Grid item sm={4} md={3} lg={2} key={`country_item_${item.name}`} className='column-center' onClick={event => onSelect(item)}>                    
                    <Card style={{ width, height }} className='row-center m-4 pt-20'>
                        <CardContent style={{ textAlign: "center" }}>
                            <img src={item.src} alt={item.name} width={'100%'} height={'100%'} />
                            <label className='f-02 bold' >
                                {item.name}
                            </label>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}

export const CountryProfile = () => {
    const dispatch = useContext(DispatchContext)
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState()
    const [scrollKeys, setScrollKeys] = useState([])
    const [scrollKey, setScrollKey] = useState('overview')

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('countryProfile')
            dispatch({ type: 'spin off' })
            setCountries(data)
        }
        fetch()
    }, [])

    const onCallback = event => {
        async function fetch() {
            if (!event) return
            dispatch({ type: 'spin on' })
            const data = await list2('countryProfile', event.id, { identifier: event.identifier })
            dispatch({ type: 'spin off' })
            setCountry(data)
            const scrollOptions = []
            for (const option of options) {
                const exist = scrollOptions.find(({ label }) => label === option.label)
                if (data[option.value] && !exist) {
                    scrollOptions.push(option)
                }
            }
            setScrollKeys(scrollOptions)
            onTab(null, 'overview')
        }
        fetch()
    }

    const goBack = () => {
        onTab(null, 'overview')
        setCountry(null)
        setScrollKeys([])
    }

    const onTab = async (event, key) => {
        setScrollKey(key)
        await sleep(0)
        const el = document.getElementById(key)
        el.scrollIntoView()
    }
    const CustomTab = withStyles({
        root: {
            backgroundColor: '#00632e',
            color: 'white',
        },
        selected: {
            backgroundColor: 'white',
        },
    })(Tab);


    return (
        <div className='column-center'>
            <div className='onTop bg-white w-full pt-10'>
                <div className='row-around w-full'>
                    {country && <IconButton className='mr-2' onClick={goBack}><Icon icon='faArrowLeft' size='1x' /></IconButton >}
                    <SelectBox options={countries} callback={onCallback} init={country?.id} placeholder='Select Country' />
                    {country && <div className='mx-2'><ExportPpt type={'country_profile'} filename={`${country.name}`} /></div>}
                </div>
                {country && <div className='row-between w-full mt-1'>
                    <Tabs value={scrollKey} onChange={onTab} variant="scrollable" >
                        {scrollKeys.map(({ label, value }) => {
                            return <CustomTab key={`key_country_profile_index_${value}`} label={<label className='f-01'>{label}</label>} value={value} />
                        })}
                    </Tabs>
                </div>}
            </div>
            {!country && <div className='mt-4 p-1'>
                <CountryList items={countries} callback={onCallback} width={'160px'} height={'120px'} />
            </div>}
            {/* <div className='row-center'>
                {country && <CountryDetail country={country} />}
            </div> */}
            {country && <div className='w-full'>
                {Object.entries(country).filter(([key, items]) => countryKeys.includes(key) && items).map(([key, items]) => {
                    return scrollKey === key && <CountryDetailSector key={`key_country_profile_sector_${key}`} id={key} items={items} />
                })}
            </div>}
        </div>
    )
}
