import { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ExpandableHeader } from '.'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import useWindow from '../../hooks/useWindow'
import moment from 'moment';
import { IconButton } from '@mui/material';
import { Icon } from '..'
import { numberCommaFormatter } from '../../services/Api';

export const FixturesGrid= ({ data }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const size = useWindow()
    const [rowData, setRowData] = useState(data)
    
    const [columnDefs, setColumnDefs] = useState([])

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        autoSizeAll()
        window.onresizable = () => {
            autoSizeAll()
        }
    };
  
    const autoSizeAll = (skipHeader) => {
        if (gridColumnApi) {
            const allColumnIds = [];
            gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };

    useEffect(() => {
        setRowData(data)
    }, [data])
    
    useEffect(() => {
        setColumnDefs([
            { headerName: 'Vessel', headerGroupComponent: 'expandableHeader', headerClass: 'long-header', children: [
                { field: 'imo', headerName: '', columnGroupShow: 'closed', minWidth: 90, cellRenderer: param => `<div class="cell-liner"><b>${param.data.vessel_name}</b></div><div class="cell-liner"></div><div class="cell-liner"><small>${param.data.imo}</small></div>` },
                { field: 'imo', headerName: 'IMO', columnGroupShow: 'open' },
                { field: 'vessel_name', headerName: 'Vessel Name', columnGroupShow: 'open', minWidth: 200 },
                { field: 'capacity', headerName: 'Capacity', columnGroupShow: 'open' },
            ]},
            { headerName: 'Grade', headerGroupComponent: 'expandableHeader', headerClass: 'short-header', children: [
                { field: 'grade', headerName: '', columnGroupShow: 'closed', minWidth: 90, cellRenderer: param => `<div class="cell-liner"><b>${param.data.grade || ''}</b></div><div class="cell-liner"></div><div class="cell-liner"><small>${param.data.sub_grade || ''}</small></div>` },
                { field: 'family', headerName: 'Family', columnGroupShow: 'open' },
                { field: 'grade', headerName: 'Grade', columnGroupShow: 'open' },
                { field: 'sub_grade', headerName: 'Sub-Grade', columnGroupShow: 'open' },
            ]},
            { headerName: 'Geography', headerGroupComponent: 'expandableHeader', children: [
                { 
                    field: 'origin_country', 
                    headerName: '', 
                    columnGroupShow: 'closed', minWidth: 90, 
                    cellRenderer: param => {
                        let origin = param.data.origin_country || param.data.origin_sub_region || param.data.origin_region || ''
                        let destination = param.data.destination_country || param.data.destination_sub_region || param.data.destination_region || ''
                        if (param.data.origin_country && param.data.origin_country === param.data.destination_country) {
                            origin = param.data.origin_port || param.data.origin_country
                            destination = param.data.destination_port || param.data.destination_country
                        }
                        return `<div class="cell-liner"><b>${origin}</b></div><div class="cell-liner">&#8595;</div><div class="cell-liner"><b>${destination}</b></div>`
                    } 
                },
                { field: 'origin_region', headerName: 'Origin Region', columnGroupShow: 'open', minWidth: 200 },
                { field: 'origin_subregion', headerName: 'Origin Sub-Region', columnGroupShow: 'open', minWidth: 200 },
                { field: 'origin_country', headerName: 'Origin Country',columnGroupShow: 'open', minWidth: 200 },
                { field: 'origin_port', headerName: 'Origin Port', columnGroupShow: 'open', minWidth: 200 },
                { field: 'destination_region', headerName: 'Destination Region', columnGroupShow: 'open', minWidth: 200 },
                { field: 'destination_subregion', headerName: 'Destination Sub-Region', columnGroupShow: 'open', minWidth: 200 },
                { field: 'destination_country', headerName: 'Destination Country', columnGroupShow: 'open', minWidth: 200 },
                { field: 'destination_port', headerName: 'Destination Port', columnGroupShow: 'open', minWidth: 200 }
            ] },
            { headerName: 'Status', headerGroupComponent: 'expandableHeader', children: [
                { field: 'trade_status', headerName: '', columnGroupShow: 'closed', minWidth: 90, cellRenderer: param => `<div class="cell-liner"><b>${numberCommaFormatter(param.data.volume_kt)} KT</b></div><div class="cell-liner"><small>${moment(param.data.load_berth_date).format('YYYY-MM-DD')}</small></div><div class="cell-liner"><small><b>${param.data.charterer || '-'}</b></small></div>` },
                { field: 'charterer', headerName: 'Charterer',columnGroupShow: 'open' },
                { field: 'volume_kt', headerName: 'Volume (KT)', columnGroupShow: 'open', valueFormatter: param => numberCommaFormatter(param.value) },
                { field: 'load_berth_date', headerName: 'Load Berth Date', valueFormatter: param => moment(param).format('YYYY-MM-DD'), columnGroupShow: 'open' },
                { field: 'trade_status', headerName: 'Trade Status', columnGroupShow: 'open' },
                { field: 'source', headerName: 'Source', columnGroupShow: 'open' }
            ]}
        ])
    }, [])
    
    const goTop = () => {
        if (gridApi) gridApi.ensureIndexVisible(0)
    }

    return (
        <div className="ag-theme-alpine ag-packed" style={{ height: size.height-250, width: '99%', minWidth: 300 }}>
            <div className='gotop'>
                <IconButton className='gotop__right' onClick={goTop}><Icon icon='faArrowAltCircleUp' size='2x' /></IconButton >
            </div>
            <AgGridReact rowData={rowData} columnDefs={columnDefs} onGridReady={onGridReady}
                frameworkComponents={{ expandableHeader: ExpandableHeader }}
                rowHeight={60}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                    flex: 1,
                    minWidth: 120,
                    suppressMovable: true,
                    cellStyle: { textAlign: 'center' }
                }}
            />
        </div>
    );
};