import { useState, useEffect } from "react";
import { Card, Grid } from '@mui/material'
import { list2, sleep } from '../services'
import { LivePriceChart } from "../components";

const LivePriceCard = ({ param }) => {
    const [data, setData] = useState([])
    const [colorCode, setColorCode] = useState('#000000')
    const [bgColor, setBgColor] = useState('bg-nuetral')
    const [lineColor, setLineColor] = useState('nuetral')
    const [name, setName] = useState('')
    const [close, setClose] = useState('')
    const [netChange, setNetChange] = useState('')
    const [percentageChange, setPercentageChange] = useState('')

    useEffect(() => {
        const { net_change, line, color } = param
        const bg_color = `bg-${color}`
        const color_code = net_change > 0 ? '#00843D' : net_change < 0 ? '#F05F41' : '#000000'
        const line_color = net_change > 0 ? 'line_green' : net_change < 0 ? 'line_red' : 'nuetral'
        const data = line.map(({ close }) => close)
        setName(param.name)
        setClose(param.close)
        setNetChange(net_change)
        setPercentageChange(param.percentage_change)
        setBgColor(bg_color)
        setLineColor(line_color)
        setColorCode(color_code)
        setData(data)
    }, [param])

    return <Card className='m-2 w-160 rounded_corner_5'>
        <div className={`p-1 w-160 ${bgColor}`}>
            <div className="column-left mb-1">
                <div className='fs-14 bold-4'>{name}</div>
            </div>
            <div className="row-between mb-1">
                <div className='fs-20 bold'>{close}</div>
                {data && colorCode && <LivePriceChart param={{ data, colorCode }} />}
            </div>
            <div className={`fs-14 bold row-between ${lineColor}`}>
                <div className=''>{netChange > 0 ? '+' : ''}{netChange}</div>
                <div className=''>{percentageChange > 0 ? '+' : ''}{percentageChange ? percentageChange.toFixed(2) : ''}%</div>
            </div>
        </div>
    </Card>
}

export const PriceLive = () => {
    const [prices, setPrices] = useState([])
    const [lastupdate, setLastupdate] = useState('')
    
    async function fetch () {
        console.log('fetching...')
        const [data, update] = await Promise.all([
            list2('price', 'live_price'),
            list2('lastupdate', 'price:intraday')
        ])
        setPrices(data)
        setLastupdate(`Update: ${update}`)
    }

    const onVisibilityChange = () => {
        console.log('visibility change', document.hidden)
        if (!document.hidden) {
            fetch()
        }
    }

    useEffect(() => {
        fetch()
        document.addEventListener("visibilitychange", onVisibilityChange)
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange)
        }
    }, [])

    useEffect(() => {
        async function refresh() {
            await sleep(300000)
            if (!document.hidden) fetch()
        }
        refresh()
    }, [prices])
    
    return (
        <div className="p-1 column-center">
            <div className={`row-between w-95p mb-6 mt-3`}>
                <label className='fs-16 bold'>Live Market</label>
                <label className='f-01 gray'>{lastupdate}</label>
            </div>
            <Grid container spacing={2} className="row-center w-full mt-3">
                {prices.map((item, index) => <Grid key={`key_live_price_card_${index}`} lg={2} item >
                    <LivePriceCard param={item} />
                </Grid>)}
            </Grid>
        </div>
    );
}