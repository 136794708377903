import { useState, useEffect, useContext } from "react";
import { IconButton, Card, CardHeader, CardContent, Typography } from '@mui/material'
import { list2 } from '../services/Api'
import { DispatchContext } from "../reducers";
import { Icon } from '../components'

export const TradeVolumeTable = ({ product, data }) => {
    return <div className="row-center m-2">
        <table className='border-1 center'>
            <tr>
                <th colSpan={data.length*2} className='border-1'>{product}</th>
            </tr>
            <tr>
            {data.map(({ year }) =>
                <td colSpan={2} key={`key_trade_volume_year_${product}_${year}`} className='border-1'>{year}</td>
            )}
            </tr>
            <tr>
                {data.map((dat) =>
                    <>
                        <td className='border-1 nw-40 bg-green'>Buy</td>
                        <td className='border-1 nw-40 bg-red_light'>Sell</td>
                    </>
                )}
            </tr>
            <tr>
            {data.map(({ year, buy, sell }) =>
                <>
                    <td key={`key_trade_volume_buy_${product}_${year}`} className='border-1 nw-40 bg-green'>{buy}</td>
                    <td key={`key_trade_volume_sell_${product}_${year}`} className='border-1 nw-40 bg-red_light'>{sell}</td>
                </>
            )}
            </tr>
        </table>
    </div>
}

export const ProfileSummary = ({ id, callback }) => {
    const dispatch = useContext(DispatchContext)
    const [company, setCompany] = useState()
    
    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const data = await list2('companyProfile', id)
            dispatch({ type: 'spin off' })
            setCompany(data)
        }
        fetch()
    }, [])

    const onCallback = (event) => {
        callback(event)
    }

    return (
        <div className=''>
            {company && <div className="row-center">                   
                <Card className='column-start' >
                    <CardHeader action={<IconButton onClick={() => onCallback('close')}><Icon icon='faTimes' /></IconButton>} 
                        title={<div className='column-left'>
                                <div><img loading="lazy" width="80" src={company.src} alt="" /></div>
                                {company.price && <Typography variant='caption'>{company.price.close}&nbsp;{(company.price.color === 'red' ? <span className={company.price.color === 'white' ? 'black' : company.price.color}>&#9660;{company.price.diffPercentage}%</span> : <span className={company.price.color === 'white' ? 'black' : company.price.color}>&#9650;{company.price.diffPercentage}%</span>)}</Typography>}
                            </div>} />
                    <CardContent style={{ textAlign: "left" }}>
                        <Typography variant='h6' color=''>Overview</Typography>
                        <Typography variant='subtitle2' color='textSecondary'>{company.overview}</Typography>
                    </CardContent>
                    <CardContent style={{ textAlign: "left" }}>
                        <Typography variant='h6' color=''>Business Segments</Typography>
                        <Typography variant='subtitle2' color='textSecondary'>{company.segments}</Typography>
                    </CardContent>
                    {company.interests && <CardContent style={{ textAlign: "left" }}>
                        <Typography variant='h6' color=''>Interests/Opportunities</Typography>
                        <Typography variant='subtitle2' color='textSecondary'>{company.interests}</Typography>
                    </CardContent>}
                    {<CardContent style={{ textAlign: "right" }}>
                        <IconButton onClick={() => onCallback(company)}><Typography variant='subtitle2' color='primary'>Detail</Typography></IconButton>
                    </CardContent>}
                    {/* {company.tradeVolume && <CardContent style={{ textAlign: "left" }}>
                        <Typography variant='caption' color='primary'>Traded Volume</Typography>
                        <Grid container>
                            {Object.entries(company.tradeVolume).map(([product, data]) => 
                                <Grid item md={6} lg={4} key={`key_trade_volume_table_${product}`}>
                                    <TradeVolumeTable product={product} data={data} />
                                </Grid>)}
                        </Grid>
                    </CardContent>} */}
                </Card>
            </div>}
        </div>
    );
}