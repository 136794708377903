import React, { useState } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { LinkBar, LinkSubBar } from '../components'
import { UploadReport } from '../pages/Upload'

const options = [
    { label: 'Report', name: 'upload/report' }
]

export const Upload = () => {
    const [tab, setTab] = useState(options[0])
    const onSelectTab = event => {
        setTab(options.find(option => option.name === event))
    }
    return (
        <div>
            <LinkBar options={options} callback={onSelectTab} />
            {tab.children && <LinkSubBar category={tab.name} options={tab.children} />}
            <Switch>
                <Route path="/upload/report" children={<UploadReport />} />
                <Redirect to="/upload/report" />
            </Switch>
        </div>
    )
}