import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from 'highcharts/modules/export-data'
exportingModule(Highcharts);
exportDataModule(Highcharts);

export const LivePriceChart = ({ param }) => {

    const [options, setOptions] = useState({})

    useEffect(() => {
        const { data, colorCode, width=100, height=50 } = param
        const options = {
            chart:  { 
                type: 'spline',
                backgroundColor: 'transparent',
                width,
                height,
            },
            tooltip: {
                style: {
                    display: "none",
                }
            },
            title: {
                text: ''
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }    
                }
            },
            yAxis: { 
                title: { text: '' },
                labels: { enabled: false },
                gridLineWidth: 0, 
            },
            xAxis: {
                title: { text: '' },
                labels: { enabled: false },
                tickLength: 0,
                lineWidth: 0,
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    color: colorCode
                }
            },
            series: data ? [{
                name: 'data',
                marker: { enabled: false },
                data
            }] : []
        }
        setOptions(options)
    }, [param]);

    return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>)
}