import { useState, useEffect, useContext } from 'react';
import { Grid, Modal, Backdrop, Fade } from '@mui/material'
import { list2 } from '../../../services/Api'
import { CftcChangeChart, CftcChart, CftcCompareChart, CftcRatioChart, CftcGrid, Lastupdate } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const Cftc = () => {
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const result = await list2('cftc')
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [])

    const onCallback = (name, param) => {
        setSelected({ field: param.colDef.field, name: param.data.name, product: name, data: param.data.chart })
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSelected(null)
    }

    return (
        <div className='column px-1 mb-6'>
            { data && data.map(({ name, date, gridData }) => {
                const mmData = gridData.data.find(({ name }) => name === 'Managed Money')
                const mmChart = { name: 'Managed Money', product: name, data: mmData.chart }
                return (<div className='column my-2' key={`key_cftc_${name}`}>
                    <div className='row-between px-2 mb-3'>
                        <div className='fs-20 bold-5'>{name}</div>
                        <div className='fs-16'>{date}</div>
                    </div>
                    <Grid container className='row-center my-2'>
                        <Grid key={`key_cftc_grid_${name}`} item md={6} className='column-center w-full my-2 direction-column'>
                            <div className={`ft-title fs-18 mb-2 ${size.width >= 960  ? 'up-20' : ''}`}>Open Interest Positions</div>
                            <CftcGrid param={gridData} callback={(param) => onCallback(name, param)} />
                        </Grid>
                        <Grid key={`key_cftc_ratio_chart_${name}`} item md={6} className='row-center w-full my-2'>
                            <CftcRatioChart param={mmChart} />
                        </Grid>
                        <Grid key={`key_cftc_compare_chart_${name}`} item md={6} className='row-center w-full my-2'>
                            <CftcCompareChart param={mmChart} />
                        </Grid>
                        <Grid key={`key_cftc_compare_chart_${name}`} item md={6} className='row-center w-full my-2'>
                            <CftcChangeChart param={mmChart} />
                        </Grid>
                    </Grid>
                </div>
                )}
            )}
            <div className='mb-6'></div>
            <Modal open={open} onClose={handleClose} className='modal' closeAfterTransition 
                BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <div className='modal__eia' style={{ width: '98%', height: size.height*0.7, maxWidth: size.width*0.98 }} >
                        {selected && <div className='column-center p-2'>
                            <CftcChart param={selected} />
                        </div>}
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}