import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer, Header, HeaderTitle } from '.';
import { Intraday } from '../components'

export const Layout = ({ children }) => {
    
    const location = useLocation()
    const [intraday, setIntraday] = useState(true) 
    
    useEffect(() => {
        if (location.pathname === '/chat') setIntraday(false)
        else setIntraday(true)
    }, [location])

    return (
        <Fragment>
            <main>
                <div className='main'>
                    {/* <HeaderTitle /> */}
                    <Header />
                    <div className='content'>{children}</div>
                    {intraday && <Intraday />}
                    <Footer />
                </div>
            </main>
        </Fragment>
    );
}