import { useEffect, useState, useContext, useRef } from "react"
import { Button, TextField } from '@mui/material'
import { DispatchContext } from "../../reducers";
import { list2, uploadReport } from '../../services/Api';
import { Icon, Message } from '../../components'
import moment from "moment";

export const UploadReport = () => {
    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const [file, setFile] = useState()
    const [settings, setSettings] = useState({ analyst: '', category: '', aspect: 'Overall', date: moment().format('YYYY-MM-DD') })

    useEffect(() => {
        async function fetch() {
            dispatch({ type: 'spin on' })
            const data = await list2('report', 'setting')
            dispatch({ type: 'spin off' })
            setSettings(data)
        }
        fetch()
    }, [])

    const handleUpload = (event) => {
        const data = event.target.files[0]
        setFile(data)
    }

    const handleSubmit = async () => {
        if (!file) {
            messageRef.current.showError('Drag and drop PDF report to the drop zone or click it to select file')
            return
        } 
        dispatch({ type: 'spin on' })
        const result = await uploadReport({ file, settings })
        dispatch({ type: 'spin off' })
        if (result === true) {
            messageRef.current.showSuccess('Upload Success')
            setFile(null)
        } else {        
            messageRef.current.showError(result.error)
        }
    }

    const handleSettingsChange = (name, event) => {
        setSettings({ ...settings, [name]: event.target.value })
    }

    return (
        <div className='column-center w-full'>
            <Message ref={messageRef} />
            <div className='mt-6 w-90p row-center'>
                <TextField label="Analyst" value={settings.analyst} onChange={(event) => handleSettingsChange('analyst', event)} />
                <TextField label="Category" value={settings.category} onChange={(event) => handleSettingsChange('category', event)} />
                <TextField label="Aspect" value={settings.aspect} onChange={(event) => handleSettingsChange('aspect', event)} />
                <TextField label="Date" value={settings.date} onChange={(event) => handleSettingsChange('date', event)} />
            </div>
            <div className='mt-6 w-90p row-center'>
                <Button component="label" color="primary" fullWidth>
                    <Icon icon='faFolderOpen' color='orange' size='2x' />
                    <input type="file" hidden onChange={handleUpload} />
                </Button>
            </div>
            {file && <div className='mt-3 w-90p row-center'>
                <div className="">{file?.name}</div>
            </div>}
            <div className='mt-6 w-90p row-center'>
                <Button onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    )
}