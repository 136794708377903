import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Grid } from '@mui/material'
import { Bar, Lastupdate, GeneralChart } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services'

const options = [
    { name: 'Crude', label: 'Crude' },
    { name: 'LPG', label: 'LPG' },
    { name: 'Naphtha', label: 'Naphtha' },
    { name: 'Gasoline', label: 'Gasoline' },
    { name: 'Gasoil', label: 'Gasoil' },
    { name: 'Jet', label: 'Jet' },
    { name: 'Fuel Oil', label: 'Fuel Oil' },
]

export const FloatingStorages = () => {
    const [tab, setTab] = useState('Crude')
    const onSelectTab = event => {
        setTab(event)
    }
    return <div>
        <Bar options={options} cls={'thirdbar'} callback={onSelectTab} />
        <FloatingStorage type={tab} />
    </div>
}

export const FloatingStorage = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()

    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const result = await list2('storage/floating', null, { category: type, split: 'No' })
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [type])

    return <div className='column-center mb-5'>
        <div className='w-90p row-between'>
            <Lastupdate type='storage:floating:kpler' align='left' />
        </div>
        <Grid container className='row-center'>
            {data && <GeneralChart param={data} />}
        </Grid>
    </div>
}