import { useState, useEffect, useContext } from 'react'
import { list2 } from '../services'
import { DispatchContext } from '../reducers'
import { Event, PriceLive } from '../layout'

export const Dashboard = () => {

    const dispatch = useContext(DispatchContext)
    const [event, setEvent] = useState()

    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const event = await list2('countryProfile', 'event')
            dispatch({ type: 'spin off' }) 
            if (event && event.name) {
                setEvent(event)
            }
        }
        fetch()
    }, [])
    
    return (
        <div className='mb-6'>
            {event ? <Event event={event} /> : <PriceLive />}
        </div>

    )
}
