import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import { DispatchContext } from "../../../reducers";
import { list2 } from '../../../services'
import { SentimentAnalysisChart } from '../../../components';
export const SentimentAnalysis = ({ type: initType }) => {
    const { type: path } = useParams()
    const type = initType || path
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [exp, setExp] = useState(false)
    useEffect(() => {
        async function fetch () {
            if (!type) return
            dispatch({ type: 'spin on' })
            const [data, auth] = await Promise.all([
                list2('sentiment', type),
                list2('auth')
            ])
            dispatch({ type: 'spin off' })
            setData(data)
            setExp(auth === true)
        }
        fetch()
    }, [type])
    return <div className='column-center mb-6'>
        {data && <SentimentAnalysisChart className='mb-6' param={data.single} />}
        {data && <SentimentAnalysisChart param={data.group} />}
    </div>
}