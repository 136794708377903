import { useState, useEffect, useContext } from 'react';
import { Modal, Typography } from '@mui/material'
import { list2 } from '../../../services/Api'
import { GeneralGrid, Lastupdate } from '../../../components';
import { DispatchContext } from "../../../reducers";
import { useWindow } from '../../../hooks'

export const CrudeRankDetail = ({ param }) => {

    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState({})

    useEffect(() => {
        const fetch = async () => {
            dispatch({ type: 'spin on' })
            const result = await list2('crude_rank', 'detail', { assay_name: param.crude })
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [])

    return <div className='column-center p-2 w-full'>
        <div className='row-left w-full'>
            <Typography variant='h6'>{param.crude}</Typography>
        </div>
        <div className='row-between w-full mt-2'>
            <div className='column-left'>
                <Typography variant='caption' sx={{ marginRight: 10 }} >{param.category}</Typography>
                <div className='row-left'>
                    <Typography variant='caption' sx={{ marginRight: '10px' }}>API {param.api}</Typography>
                    <Typography variant='caption'>SUL {param.sul}</Typography>
                </div>
            </div>
            <div className='column-right'>
                <Typography variant='caption'>Vessel Type: {data.vessel_type}</Typography>
                <Typography variant='caption'>{param.origin} {'->'} {param.destination}</Typography>
            </div>
        </div>
        <div className='column-center w-full'>
            <GeneralGrid param={data} />
        </div>
    </div>
}

export const CrudeRank = () => {
    const size = useWindow()
    const dispatch = useContext(DispatchContext)
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()
    
    useEffect(() => {
        async function fetch () {
            dispatch({ type: 'spin on' })
            const result = await list2('crude_rank')
            dispatch({ type: 'spin off' })
            setData(result)
        }
        fetch()
    }, [])

    const onClickGridCell = (param) => {
        console.log(param)
        if (param.colDef.field === 'crude') {
            setSelected(param.data)
            setOpen(true)
        }
    }

    return (
        <div className='row-center'>
            <div className='column px-1 w-full'>
                <div className='row-between mt-2 mb-3'>
                    <Typography variant='h6' className="w-full">Asia Crude Ranking</Typography>
                    <Lastupdate type='crude_rank' />
                </div>
                {/* for single crude rank page, size.height-140 */}
                {data && <GeneralGrid param={{ ...data, maxHeight: size.height-240 }} callback={onClickGridCell} />}
            </div>
            <Modal open={open} onClose={() => setOpen(false)} className='row-center'>
                <div className='p-1 bg-white position-absolute w-90p' style={{ height: 650, maxWidth: size.width*0.95 }} >
                    {selected && <div className='column-center p-2'>
                        <CrudeRankDetail param={selected} />
                    </div>}
                </div>
            </Modal>
        </div>
    )
}