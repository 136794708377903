import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { gridHeight } from '../../services/Api';
import cellRenderers from './CellRenderer'
import cellClasses from './CellClass'
import valueFormatters from './ValueFormatter'
import { TwoLineHeader } from './CftcGrid'

export const HeaderBreakWord = (props) => {
    const { header } = props
    return <div className='column-center break-word bold-7 w-full'>{header}</div>
}

export const GeneralGrid = ({ param, callback }) => {
    const [gridApi, setGridApi] = useState();
    const [rowData, setRowData] = useState([])
    const [columnDefs, setColumnDefs] = useState([])
    const [defaultCellPosition, setDefaultCellPosition] = useState('center')
    const [defaultHeaderPosition, setDefaultHeaderPosition] = useState('center')
    const [maxHeight, setMaxHeight] = useState(600)

    const onGridReady = (params) => {
        setGridApi(params.api);
        sizeToFit();
        window.onresizable = () => {
            sizeToFit();
        }
    };
  
    const sizeToFit = () => {
        if (gridApi) gridApi.sizeColumnsToFit();
    };

    useEffect(() => {
        console.log(param)
        const { data, colDef, defaultCellPosition = 'center', defaultHeaderPosition = 'center', maxHeight } = param
        if (data && colDef) {
            const colDef_ = colDef.map(({ chart, valueFormatter, cellRenderer, cellClass, ...rest }) => ({
                valueFormatter: valueFormatters[valueFormatter], 
                cellRenderer: cellRenderers[cellRenderer], 
                cellClass: cellClasses[cellClass],
                onCellClicked: param => onClickCallback(param), ...rest 
            }))
            console.log(colDef_)
            setColumnDefs(colDef_)
            setRowData(data)
            setDefaultCellPosition(defaultCellPosition)
            setDefaultHeaderPosition(defaultHeaderPosition)
            setMaxHeight(maxHeight || 600)
        }
    }, [param])
    
    const onClickCallback = (param) => {
        callback(param)
    }
    
    return (
        <div className="column-center w-full">
            {param && param.data && <div className={`ag-theme-alpine ag-packed ag-header-${defaultHeaderPosition}`} style={{ maxHeight, minHeight: 100, height: gridHeight(param.data.length, 36), width: '100%' }}>
                <AgGridReact rowData={rowData} columnDefs={columnDefs} rowHeight={36} onGridReady={onGridReady} headerHeight={36} frameworkComponents={{ TwoLineHeader }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 60,
                        wrapText: true,
                        suppressMovable: true,
                        suppressHorizontalScroll: true,
                        cellStyle: { 
                            height: '100%',
                            display: 'flex',
                            justifyContent: defaultCellPosition,
                            alignItems: 'center' 
                        }
                    }}
                />
            </div>}
        </div>
    );
};