import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { list2 } from '../services';
import { DispatchContext } from "../reducers";
import { Message } from '../components'
import { Card, CardContent, TextField, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import * as QRCode from 'easyqrcodejs';
import logo from '../pics/logo_qr.png'
 
export const Qrcode = () => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [title, setTitle] = useState('')
    const [workEmail, setWorkEmail] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [workPhone, setWorkPhone] = useState('')
    const [org, setOrg] = useState('')
    const [website, setWebsite] = useState('')
    const [addrOne, setAddrOne] = useState('')
    const [addrTwo, setAddrTwo] = useState('')
    const [street, setStreet] = useState('')
    const [locality, setLocality] = useState('')
    const [region, setRegion] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [country, setCountry] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [qrOptions, setQrOptions] = useState(null)
    const [bwQr, setBwQr] = useState('aramco')

    const [open, setOpen] = useState(false)

    const messageRef = useRef()
    const dispatch = useContext(DispatchContext)
    const history = useHistory()

   
    useEffect(() => {
        async function authUser()  {
            const auth = await list2('auth' , 'qr')
           
            if(!auth || auth.length === 0) {
                history.push('/')
                window.location.reload()
            } 
        }

        authUser()
        async function fetch () {
            dispatch({ type: 'spin on' })
            const {
                first_name,
                last_name,
                title,
                email,
                mobile,
                work_phone,
                organization,
                website,
                address1,
                address2,
                street,
                locality,
                region,
                postal_code,
                country
            } = await list2('user')

            setFirstName(first_name ?? '')
            setLastName(last_name ?? '')
            setTitle(title ?? '')
            setWorkEmail(email ?? '')
            setMobileNo(mobile ?? '') 
            setWorkPhone(work_phone ?? '') 
            setOrg(organization ?? '') 
            setRegion(region ?? '')
            setWebsite(website ?? '')
            setAddrOne(address1 ?? '')
            setAddrTwo(address2 ?? '')
            setStreet(street ?? '')
            setLocality(locality ?? '')
            setPostalCode(postal_code ?? '')
            setCountry(country ?? '')
            // SAO, ATS, ATL, 
            dispatch({ type: 'spin off' })
        }
        fetch()

        
    }, [])


    useEffect(() => {
        if(firstName && lastName && title && mobileNo && org)
            setIsDisabled(false)
        else setIsDisabled(true)
    }, [firstName, lastName, title, mobileNo, org])



    const handleChange = (event) => {
        setBwQr(event.target.value);
      };
    

    
    const handleGenerateQR = async () => {
        const bgImageAlpha = 0.8;
        const quietZone = 8;
        let address = ''

        // Format - Address Line 1; Extended Address; Street; Locality; Region; Postal Code; Country
        switch(region) {
            case 'ATC': 
                address = 'ADR;TYPE=work:Al Midra Tower;Saudi Aramco;Al Midra Blvd;Dhahran;Saudi Arabia;34481;Saudi Arabia'; 
                break;

            case 'ATS': 
                address = 'ADR;TYPE=work:;;50 Collyer Quay #13-02;Singapore;;049321;Singapore';
                break;

            case 'ATL':
                address = 'ADR;TYPE=work:;;10 Portman Square;London;Europe;W1H6AZ;United Kingdom';
                break;

            case 'ATF':
                address = 'ADR;TYPE=work:Al Hilal Business Tower;14th Floor;Office No. 1401-1402;Fujairah;United Arab Emirates;50973;United Arab Emirates';
                break;

            case 'SAO':
                address = 'ADR;TYPE=work:Saudi Aramco;Main Admin Building; Rm MA-240;Dhahran;Saudi Arabia;31311;Saudi Arabia'
                break;
            
            case 'ATA': 
                address = 'ADR;TYPE=work:Allen Center;;500 Dallas Street;Texas;North America;TX 77002;United States of America'
                break;
            
            default: 
                address = ''
        }


        let aramco_logo_option = {
             // ====== Basic
           // ADR;TYPE=work:Al Midra Tower;Saudi Aramco;Al Midra Blvd;Dhahran;Saudi Arabia;34481;Saudi Arabia
           // ADR;TYPE=work:;;50 Collyer Quay #13-02;Singapore;;049321;Singapore
           // ADR;TYPE=work:;;10 Portman Square;London;Europe;W1H6AZ;United Kingdom
           // ADR;TYPE=work:Al Hilal Business Tower;14th Floor;Office No. 1401-1402;Fujairah;United Arab Emirates;50973;United Arab Emirates
           // Correct address - Saudi Aramco;Main Admin Building; Rm MA-240;Dhahran;Saudi Arabia;31311;Saudi Arabia
           
           text: `BEGIN:VCARD\r\nVERSION:3.0\r\nN:${lastName};${firstName};;;\r\nFN:${firstName} ${lastName}\r\nORG:${org}\r\nEMAIL;TYPE=work:${workEmail}\r\nTITLE:${title}\r\nTEL;TYPE=mobile:${mobileNo}\r\nTEL;TYPE=work:${workPhone}\r\nURL:${website}\r\n${address}\r\nEND:VCARD`,
           width: 210,
           height: 210,
           colorDark : "#2C4F33", //#53BB34 #07648B
           colorLight : "#fff",
           correctLevel : QRCode.CorrectLevel.H, // L, M, Q, H
           
           // ====== dotScale
           
           dotScale: 0.7, // For body block, must be greater than 0, less than or equal to 1. default is 1
           
           dotScaleTiming: 0.6, // Dafault for timing block , must be greater than 0, less than or equal to 1. default is 1
           dotScaleTiming_H: 0.6, // For horizontal timing block, must be greater than 0, less than or equal to 1. default is 1
           dotScaleTiming_V: 0.6, // For vertical timing block, must be greater than 0, less than or equal to 1. default is 1
           
           dotScaleA: 0.6, // Dafault for alignment block, must be greater than 0, less than or equal to 1. default is 1
           dotScaleAO: 0.6, // For alignment outer block, must be greater than 0, less than or equal to 1. default is 1
           dotScaleAI: 0.6, // For alignment inner block, must be greater than 0, less than or equal to 1. default is 1
           
       
           // ====== Quiet Zone
           
           quietZone,
           quietZoneColor: "rgba(255,255,255)",
           

           // ====== Backgroud Image
           backgroundImage: logo, // Background Image
           backgroundImageAlpha: bgImageAlpha, // Background image transparency, value between 0 and 1. default is 1. 
           autoColor: true, // Automatic color adjustment(for data block)
           autoColorDark: "rgba(0, 0, 0, .6)", // Automatic color: dark CSS color
           autoColorLight: "rgba(255, 255, 255, .7)", // Automatic color: light CSS color
           
       
           
           //onRenderingStart: undefined,
           onRenderingEnd: (qrOptions, dataUrl) => {
             aramco_logo_option.dataUrl = dataUrl;
           },
           
       
         
        }

        let bw_option = {
          
          text: `BEGIN:VCARD\r\nVERSION:3.0\r\nN:${lastName};${firstName};;;\r\nFN:${firstName} ${lastName}\r\nORG:${org}\r\nEMAIL;TYPE=work:${workEmail}\r\nTITLE:${title}\r\nTEL;TYPE=mobile:${mobileNo}\r\nTEL;TYPE=work:${workPhone}\r\nURL:${website}\r\n${address}\r\nEND:VCARD`,
          width: 210,
          height: 210,
          colorDark : "#000", //#53BB34 #07648B
          colorLight : "#fff",
          correctLevel : QRCode.CorrectLevel.H, // L, M, Q, H
          
          // ====== dotScale
          
          dotScale: 0.7, // For body block, must be greater than 0, less than or equal to 1. default is 1
          
          dotScaleTiming: 0.6, // Dafault for timing block , must be greater than 0, less than or equal to 1. default is 1
          dotScaleTiming_H: 0.6, // For horizontal timing block, must be greater than 0, less than or equal to 1. default is 1
          dotScaleTiming_V: 0.6, // For vertical timing block, must be greater than 0, less than or equal to 1. default is 1
          
          dotScaleA: 0.6, // Dafault for alignment block, must be greater than 0, less than or equal to 1. default is 1
          dotScaleAO: 0.6, // For alignment outer block, must be greater than 0, less than or equal to 1. default is 1
          dotScaleAI: 0.6, // For alignment inner block, must be greater than 0, less than or equal to 1. default is 1
          
      
          // ====== Quiet Zone
          
          quietZone,
          quietZoneColor: "rgba(255,255,255)",
          
          
          onRenderingEnd: (qrOptions, dataUrl) => {
            bw_option.dataUrl = dataUrl;
          },
          
      
        
       }
      
       if(bwQr === 'bw') setQrOptions(bw_option) 
       else setQrOptions(aramco_logo_option)
       
        setOpen(true)
    }
   
    const handleClose = () => {
        setOpen(false)
    }

    const QrCodeDialog = (props) => {
        const { qrOptions, open, onClose } = props
        const [visible, setVisible] = useState(true);
        const qrCodeRef = useRef(null);

       

        useEffect(() => {
            if(open) {
                    setTimeout(() => { 
                        setVisible(false);
                        new QRCode(qrCodeRef.current, qrOptions); 
                    }, 2000)
            }

            return () => {
                setVisible(true);
            }
            
            
         }, [qrOptions, open])

         const handleDownloadQR = () => {
            downloadURI(qrOptions.dataUrl, 'QR-Code')
         }

         const downloadURI = (uri, name) => {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
    
        return (
           
            <Dialog  PaperProps={{ style: { backgroundColor: '#AAAFB4',
            color: '#E6EFF4'} }} onClose={onClose} open={open}>
               
                 <DialogTitle>QR Code</DialogTitle>
                    <DialogContent>
                        <div className="qr_qrCode" ref={qrCodeRef}>
                            {visible && <span style={{color: 'white'}}> Loading... </span>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" className="qr_downloadBtn" onClick={handleDownloadQR}>Download</Button>
                        <Button variant="outlined" className="qr_downloadBtn" onClick={onClose}>Close</Button>
                    </DialogActions>
                    
            </Dialog>
        )
    }
    
    return (
        <div className="qr_root">
            <Message ref={messageRef} />

            <QrCodeDialog
                qrOptions={qrOptions}
                onClose={handleClose}
                open={open}
            >
            </QrCodeDialog>
            <div className="row-center"> 
                <img src={logo} width="50" height="50" className="qr_logoRadius" />
                <h2 className="qr_title"> QR Code Generator </h2>
            </div>
          
            
            <Card elevation={3} className="qr_card" noValidate autoComplete="off">
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        User Information
                    </Typography>
                   
                    <div className="qr_fieldsWrapper">
                        <TextField disabled className="w-300 m-1" value={firstName} onInput={ e=>setFirstName(e.target.value)} variant="outlined" label="First Name" required />
                        <TextField disabled className="w-300 m-1" value={lastName} onInput={ e=>setLastName(e.target.value)} variant="outlined" label="Last Name" required />
                        <TextField disabled className="w-300 m-1" value={title} onInput={ e=>setTitle(e.target.value)} variant="outlined" label="Title" required />
                        <TextField disabled className="w-300 m-1" value={ workEmail } onInput={ e=>setWorkEmail(e.target.value)} variant="outlined" label="Work Email" />
                        <TextField disabled className="w-300 m-1" value={mobileNo} onInput={ e=>setMobileNo(e.target.value)} variant="outlined" label="Mobile No." required />
                        <TextField disabled className="w-300 m-1" value={workPhone} onInput={ e=>setWorkPhone(e.target.value)} variant="outlined" label="Work Phone" />
                        <TextField disabled className="w-300 m-1" value={org} onInput={ e=>setOrg(e.target.value)} variant="outlined" label="Organization" required />
                        <TextField disabled className="w-300 m-1" value={region} onInput={ e=>setRegion(e.target.value)} variant="outlined" label="Region" />


                        <FormControl>
                            <FormLabel className="white" id="demo-row-radio-buttons-group-label">QR Code Background:</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={bwQr}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="bw" control={<Radio />} label="Black & White" />
                                <FormControlLabel value="aramco" control={<Radio />} label="Aramco Logo" />   
                            </RadioGroup>
                        </FormControl>

                    </div>
                </CardContent>
            </Card>

            <div className="row-center">
                <Button elevation={3} className="qr_generateBtn" variant="contained" onClick={handleGenerateQR}>Generate QR Code</Button>
            </div>
            
        </div>
    );
}
